Object.defineProperty(Object.prototype, 'getPathValue', {
	value: function(path) {
		return path.split('.').reduce(function(obj, key) {
			if (obj) {
				return obj[key];
			}

			return false;
		}, this);
	},
	writable: true,
	configurable: true,
	enumerable: false
});